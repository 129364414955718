import * as React from 'react';
import { Banner } from '@mentimeter/ragnar-ui/banner';
import { Link } from '@mentimeter/ragnar-ui/link';
import { Text } from '@mentimeter/ragnar-ui/text';
import { gaTrackEvent } from '../../utils/tracker';
import { useCookieConsent } from './CookieConsent';

export const CookieBanner = () => {
  const { cookiesEnabled, acceptCookies } = useCookieConsent();

  if (cookiesEnabled) return null;

  return (
    <Banner theme="dark" onDismiss={acceptCookies}>
      <Text textAlign="center" color="inherit">
        We use cookies to provide this service and improve your experience.{' '}
        <Link
          color="inherit"
          style={{ whiteSpace: 'nowrap' }}
          href="https://www.mentimeter.com/cookie-policy"
          target="_blank"
          rel="noreferrer noopener"
          onClick={() => gaTrackEvent('link', 'clicked', 'Cookie policy')}
        >
          Learn more
        </Link>
      </Text>
    </Banner>
  );
};
