import { fromSnakeKeys } from '@mentimeter/migration-utils/snake-keys';
import {
  voting,
  type VotingSeriesWithSlideDeck,
} from '@mentimeter/http-clients';
import { getRegionByVoteId, getRegionByVoteKey } from '@mentimeter/region';

export async function getSlideDeckByKey(
  key: string,
  queryParams: URLSearchParams,
): Promise<VotingSeriesWithSlideDeck> {
  const region = getRegionByVoteKey(key);

  const { data } = await voting({ region }).slideDeck.getByKey(
    key,
    queryParams,
  );
  const series = data.series;
  const dataWithCamelKeys = fromSnakeKeys(data);
  return {
    series,
    slideDeck: dataWithCamelKeys.slideDeck,
  };
}

export async function getParticipationKeyByParticipationCode(
  participationCode: string,
): Promise<{ participationKey: string }> {
  if (!participationCode || !participationCode.replace) {
    return Promise.reject(new Error('Invalid participationCode'));
  }
  const digitsOnlyParticipationCode = participationCode.replace(/[^0-9]/g, '');

  const region = getRegionByVoteId(digitsOnlyParticipationCode);

  const { data } = await voting({
    region,
  }).slideDeck.getParticipationKeyByParticipationCode(
    digitsOnlyParticipationCode,
  );

  return fromSnakeKeys(data);
}
